import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "project-setup"
    }}>{`Project setup`}</h1>
    <p>{`Our project needs a modern JAMStack frontend and a couple cloud functions. We're using NextJS to make that easier with `}<inlineCode parentName="p">{`/api`}</inlineCode>{` routes and deploying to Vercel.`}</p>
    <p>{`For a more serious project (as soon as you need a DB), I recommend using Serverless Framework for the backend. Gives you more control and keeps all server-side stuff together.`}</p>
    <p>{`Pull from my GitHub repo to get started: `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/twitshot.xyz"
      }}>{`https://github.com/Swizec/twitshot.xyz`}</a></p>
    <p>{`You get a vanilla-ish NextJS project with ThemeUI and MDX support.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      